import { Box, Typography } from "@mui/material"


export const About = ()=>{
    return(
 
        <Box>
            <Typography variant="h3" sx={{fontWeight:900,textAlign:"left"}} gutterBottom>JYH WORKS</Typography>
            <Typography variant='h5' textAlign="justify" sx={{fontWeight:900,color:"#b63831"}}>專業的網站與網頁設計公司</Typography>
            <Typography variant='h5' textAlign="justify" sx={{fontWeight:900,color:"#b63831",mb:3}}>RWD、SEO、客製化網站設計一次滿足</Typography>
            <Typography variant='body1' textAlign="justify" color="textSecondary" sx={{fontWeight:900}}>
                由三位工程師所創立，專注於RWD網頁開發、後端系統開發、SEO關鍵字優化以及UI/UX設計服務。提供優質的網站解決方案，並以創新設計和專業技術為客戶帶來卓越的使用者體驗和業務成長。
            </Typography>
            <Box><img src="../img/sample.png" width="80%"/></Box>
        </Box>

    )
}