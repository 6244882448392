import { Stack, Typography } from "@mui/material"

export const Servicefooter = ()=>{
    return (
        <Stack spacing={2} textAlign="left">
            <Typography variant='h6' sx={{fontWeight:900}} component="h5">服務項目</Typography>
            <Stack direction="column" spacing={1} >
                <Typography variant='subtitle1' color="text.secondary" textAlign="left" sx={{textIndent:"-8rem",pl:"8rem"}}>
                    RWD網站開發 － 形象官網、客製化網站、多頁式網站、一頁式網站、Wordpress 
                </Typography>
                <Typography variant='subtitle1' color="text.secondary" textAlign="left" sx={{textIndent:"-7.6rem",pl:"7.6rem"}}>
                    後台程式開發 － 會員系統、薪資計算系統、產品管理系統、其他後台系統
                </Typography>
                <Typography variant='subtitle1' color="text.secondary" textAlign="left" sx={{textIndent:"-9.5rem",pl:"9.5rem"}}>
                    官方帳號程式開發 － Line Bot聊天機器人功能、AI智能客服、其他系統串接
                </Typography>
                <Typography variant='subtitle1' color="text.secondary" textAlign="left">
                    SEO 關鍵字優化 － 提供網站優化、提升網站流量
                </Typography>
                <Typography variant='subtitle1' color="text.secondary" textAlign="left">
                    免費諮詢服務 － 網站諮詢、網站健檢
                </Typography>
            </Stack>
      </Stack>
    )
}