import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import CodeOffOutlinedIcon from '@mui/icons-material/CodeOffOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import { Box, Grid, Paper, Typography } from '@mui/material';

export const Service = ()=>{
    const aboutItem = [
        {title:"RWD 網站開發",content:"開發具有響應式設計的網站，以適應各種設備和螢幕尺寸，提升使用者體驗和網站流量。",icon:<CodeOffOutlinedIcon  sx={{mb:1,position:"relative",zIndex:"1",fontSize:"50px"}}/>},
        {title:"UI/UX 設計",content:"提供直覺、吸引人且易於使用的界面，提升使用者體驗，強化品牌形象，並促進業務成長。",icon:<DesignServicesOutlinedIcon  sx={{mb:1,position:"relative",zIndex:"1", fontSize:"50px"}}/>},
        {title:"SEO 關鍵字優化",content:"提供專業的SEO關鍵字優化服務，讓您的網站在搜索引擎中脫穎而出，吸引更多流量和潛在客戶。",icon:<AdsClickOutlinedIcon  sx={{mb:1,position:"relative",zIndex:"1",fontSize:"50px"}}/>},
        {title:"免費諮詢與健檢",content:"提供網站開發服務諮詢，並對現有網站進行免費檢測，提供優化建議，讓您的網站更有效率。",icon:<MonitorHeartOutlinedIcon  sx={{mb:1,position:"relative",zIndex:"1",fontSize:"50px"}}/>},
      ]
    
      return (
        <Grid container spacing={2}  sx={{minHeight:{lg:"820px"}}}>
            {aboutItem.map((item,index)=>(
            <Grid key={index} item mx="auto" xs={10} sm={6} sx={index%2===1?{position:{lg:"relative"},top:{lg:"70px"}}:{}}>
                <Box>
                    <Paper elevation={6} square={false} sx={{p:5,minHeight:"250px",textAlign:"left",borderRadius:"20px",width:{xl:"70%"}}}>
                    <div className='cirlecontainer'>
                        {item.icon}
                        <div className='circle'></div>
                    </div>
                    <Typography variant='h6' textAlign="center" sx={{fontWeight:700,my:2}}>{item.title}</Typography>
                    <Typography variant='body1' textAlign="justify">{item.content}</Typography>
                    </Paper>
                </Box>
            </Grid>
            ))}
        </Grid>   
      )
}



