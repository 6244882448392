import { Stack, Typography } from "@mui/material"

export const Studiofooter = ()=>{
    return (
        <Stack spacing={2} textAlign="left">
            <Typography variant='h6' sx={{fontWeight:800}} component="h5">JYH WORKS</Typography>
            <Typography variant='subtitle1' color="text.secondary" textAlign="justify">
                專注於RWD網頁開發與後端系統開發，提供創新、專業的網站解決方案，為客戶打造出色的線上體驗。
            </Typography>
            <Stack direction="row" spacing={2} >
                <a href="https://www.instagram.com/jyh_works?igsh=Zm5iczR0anlvZmV3&utm_source=qr" target="_blank"><img src="img/ig.png" width="30" height="30" alt="ig" /></a>
                <a href="https://lin.ee/VcMOMSt" target="_blank"><img src="img/line1.png" width="30" height="30" alt="line" /></a>
            </Stack>
      </Stack>
    )
}