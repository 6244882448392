import { Box, Card, CardActions, CardContent, CardMedia, Grid, ListItem, Typography } from "@mui/material"
import { useEffect } from "react"
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';

export const Member = ()=>{
    const members = [
        {pic:"pic1.png",name:"Jason",title:"軟體工程師",edu:"行動平台App、後台資料管理系統、資料視覺化、鏈結開放資料網站及資料庫平台及API開發、內容管理系統(CMS)客製化模組、聊天機器人"},
        {pic:"pic2.png",name:"Yung",title:"軟體工程師",edu:"形象官網、研究學者使用的分析平台、電子發票系統、直銷內部會員系統、生物資訊網站"},
        {pic:"pic3.png",name:"Hong",title:"軟體工程師",edu:"形象官網、教學網站、線上購物商城、保全媒合系統、資訊教育訓練課程"},
        {pic:"pic2.png",name:"HSIN TING",title:"專案經理",edu:"顧客管理系統(CRM)、排課系統、簽核系統、進銷存系統"},
      ]

      useEffect(()=>{
        members.map((member)=>{
            new Image().src = "img/" + member.pic;
        })
      },[])
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2} rowSpacing={10} >
            {members.map((member)=>(
            <Grid key={member.name} item xm={12} sm={10} md={8} lg={3} sx={{textAlign:"center"}} alignItems="center" alignContent="center"> 
            <Box sx={{width:"80%",mx:"auto"}}  >
                <Card sx={{borderRadius:"20px",height:"670px"}}> 
                <CardMedia component="img" image={"img/" + member.pic} />
                <CardContent>
                    <Typography variant="h5" gutterBottom>{member.name}</Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>{member.title}</Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{textDecoration:"underline",fontWeight:800}}>經歷</Typography>
                    <Typography variant="body2" color="text.secondary" textAlign="justify" sx={{minHeight:{lg:"141px",xl:"121px"},mt:2}}>
                        {member.edu.split("、").map((item)=>( 
                            <ListItem><PlayArrowSharpIcon fontSize="small" sx={{mr:1}}/>{item}</ListItem>
                        ))}
                    </Typography>
                    
                </CardContent>
                <CardActions></CardActions>
                </Card>
            </Box>
            </Grid>
            ))}
        </Grid>   
    )
}