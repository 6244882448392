import { Box, Divider, Grid, Paper, Typography } from "@mui/material"
import { red } from '@mui/material/colors';
import UploadIcon from '@mui/icons-material/Upload';

export const Cases = ()=>{ 
    const products =[
        {id:"RWD網站開發",price:"35,000",details:"形象官網、客製化網站、多頁式網站、一頁式網站、Wordpress"},
        {id:"SEO網站優化",price:"50,000",details:"網站架構最佳化、網頁內容最佳化、外部連結最佳化"},
        {id:"後台程式開發",price:"50,000",details:"會員系統、薪資計算系統、產品管理系統、其他後台系統"},
        {id:"官方帳號程式開發",price:"35,000",details:"Line Bot聊天機器人、AI智能客服、其他系統串接"},
    ]
    
    return (
        <>
        <Typography variant='h3' sx={{fontWeight:800}} gutterBottom>方案介紹</Typography>
        <Grid container spacing={2}>
            {products.map((product)=>(
            <Grid item xs={10} sm={8} md={6} lg={5} xl={3}  key={product.id} mx="auto" >
            <Box sx={{mx:"auto"}}>
                <Paper elevation={2} sx={{py:8,borderRadius:"20px",minHeight:"485px"}}>
                <Typography bgcolor="rgb(237,176,55)" sx={{fontWeight:800,mb:2,mx:{xs:10,xl:5},py:1,fontSize:{xs:"1.25rem",xl:"1rem"}}} gutterBottom>{product.id}</Typography>
                <Typography sx={{fontWeight:900,mb:6,fontSize:{xs:"3.75rem",xl:"3rem"}}} gutterBottom>${product.price}<UploadIcon fontSize={'small'} sx={{color:red[700]}} /></Typography>
                <Box sx={{mx:6}}>
                    {product.details.split("、").map((detail,index)=>(
                    <Box key={detail}>
                        <Typography variant='body1' color="text.secondary" textAlign="left" fontWeight={800} p={2} px={1}>{detail}</Typography>
                        {index!==product.details.split("、").length-1?<Divider />:""}
                    </Box>
                    ))}
                </Box>
                </Paper>
            </Box>
            </Grid>
            ))}
        </Grid>
        </>
    )
}