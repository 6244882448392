import { Stack, TextField, Typography,InputAdornment, Paper, Button } from "@mui/material";
import { AccountCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TitleIcon from '@mui/icons-material/Title';
import { useState } from 'react';
import emailjs from 'emailjs-com';



export const Contact = ()=>{
    const [checked,setChecked] = useState({});
    const [contact,setContact] = useState({});
  
    const handleContact = (e)=>{
      const new_contact = Object.assign({},contact,{[e.target.id]:e.target.value});
      const ans = (!e.target.value || e.target.value==="")?true:false;
      const new_checked = Object.assign({},checked,{[e.target.id]:ans});
      setContact(new_contact);
      setChecked(new_checked);
      console.log(checked);
    }

    const sendEmail = ()=>{
        if (!contact.customerName || 
            !contact.customerSubject || 
            !contact.customerContent || 
            !contact.customerMail || 
            !contact.customerPhone){
            alert("有資料尚未填寫!");
        }else{
            const templateParams = {
                name: contact.customerName,
                title: contact.customerSubject,
                message: contact.customerContent,
                email: contact.customerMail,
                phone: contact.customerPhone
            }
    
            emailjs.send("service_kw7ny1g","template_fxxpmyv",templateParams,'eCGOe6zNnANTzrPtu')
            .then(()=> {
                alert('謝謝您的填寫，將有專人與您聯繫!');
             }, (error)=> {
                console.log('FAILED...');
             });
        }
    }

    return(
        <>
        <Typography variant='h3' sx={{fontWeight:800,textAlign:"left"}} gutterBottom>聯絡我們</Typography>
        <Paper elevation={10} sx={{borderRadius:"10px",width:{md:"70%",lg:"60%",xl:"50%"},p:1}}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} bgcolor="white"  sx={{borderRadius:"10px",p:2}}>
                <TextField id="customerName" label="姓名" variant="outlined" required fullWidth onChange={(e)=>handleContact(e)} error={checked.customerName} 
                    InputProps={{
                    startAdornment:<InputAdornment position='start'> <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></InputAdornment>,
                }} 
                InputLabelProps={{
                    style: { color: 'black' }
                }} />
                <TextField id="customerMail" label="信箱" variant="outlined" required fullWidth onChange={(e)=>handleContact(e)} error={checked.customerMail} 
                    InputProps={{
                    startAdornment:<InputAdornment position='start'> <MailOutlineIcon /></InputAdornment>
                }}
                InputLabelProps={{
                    style: { color: 'black' }
                }}/>
                <TextField id="customerPhone" label="電話" variant="outlined" required fullWidth onChange={(e)=>handleContact(e)} error={checked.customerPhone} 
                    InputProps={{
                    startAdornment:<InputAdornment position='start'> <PhoneAndroidIcon /></InputAdornment>
                }} 
                InputLabelProps={{
                    style: { color: 'black' }
                }}/>
                <TextField id="customerSubject" label="主旨" variant="outlined" required fullWidth onChange={(e)=>handleContact(e)} error={checked.customerSubject} 
                    InputProps={{
                    startAdornment:<InputAdornment position='start'> <TitleIcon /></InputAdornment>
                }} 
                InputLabelProps={{
                    style: { color: 'black' }
                }}/>
                <TextField id="customerContent" label="內容" multiline rows={8} required fullWidth onChange={(e)=>handleContact(e)} error={checked.customerContent} 
                InputLabelProps={{
                    style: { color: 'black' }
                }} />
                <Button className="buy" variant="contained" fullWidth onClick={sendEmail}>送出</Button>
            </Stack>
        </Paper>
        </>
    )
}