import { Box, Stack, Typography } from "@mui/material"
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const Contactfooter = ()=>{
    return(
        <Stack spacing={2} textAlign="left">
            <Typography variant='h6' sx={{fontWeight:900}} component="h5">聯絡我們</Typography>
            <Stack direction="column" spacing={1} >
                {/* <Box sx={{display:"flex",justifyItems:"center"}}>
                    <img src="img/line2.png" width="22px" height="22px" alt="line" />
                <Typography ml={2} variant='subtitle1' color="text.secondary" textAlign="justify">
                    Line:
                </Typography>
                </Box> */}
                <Box sx={{display:"flex",justifyItems:"center"}}>
                    <MailOutlineIcon />
                    <Typography ml={2} variant='subtitle1' color="text.secondary" textAlign="justify" >
                        Email: <a href="mailto:jyhworks@gmail.com">jyhworks@gmail.com</a>
                    </Typography>
                </Box>
            </Stack>
      </Stack>
    )
}