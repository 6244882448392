import './App.css';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Card, CardContent, CardMedia, Divider, Drawer, Grid, IconButton,  Link,  List,  ListItem,  ListItemButton,  ListItemText,  Paper,  SpeedDial,  SpeedDialAction,  SpeedDialIcon,  Stack, Stepper } from '@mui/material';
import { About } from './about/About';
import { Service } from './about/Service';
import { Member } from './member/Member';
import { Contact } from './contact/Contact';

import { Studiofooter } from './footer/Studiofooter';
import { Servicefooter } from './footer/Servicefooter';
import { Contactfooter } from './footer/Contactfooter';

import { useEffect, useRef, useState } from 'react';

import { Cases } from './cases/Cases';
import { Knowledge } from './knowledge/Knowledge';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import InstagramIcon from '@mui/icons-material/Instagram';
import SvgIcon from '@mui/material/SvgIcon';
import PhoneIcon from '@mui/icons-material/Phone';
import TerminalIcon from '@mui/icons-material/Terminal';
import ChatIcon from '@mui/icons-material/Chat';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const menuItem = [
  {id:"#about",title:"關於JYH"},
  {id:"#plan",title:"方案介紹"},
  {id:"#kw",title:"網頁小知識"},
  {id:"#member",title:"專業團隊"},
  // {id:"#case",title:"成功案例"},
  {id:"#contact",title:"聯絡我們"},
]

const steps = [
  {
    label: 'RWD跨平台完美呈現',
    description: `RWD全名為Responsive Web Design，是一種網頁設計技術，目的在於使網站能夠在各種裝置上（如桌面電腦、平板電腦、智慧手機等）都能呈現最佳的使用體驗。RWD透過使用彈性的佈局、彈性的圖像和媒體查詢等技術，根據訪問者的螢幕大小、解析度和設備特性來調整網站的外觀和佈局。這種設計方法讓網站在不同裝置上都能保持一致、流暢且易於閱讀和導航。`,
    pic:"RWD.jpg"
  },
  {
    label: 'SEO優化是什麼？',
    description: `SEO(Search Engine Optimization)是一種網站優化技術，旨在提高網站在搜索引擎結果中的排名。通過優化網站結構、內容和外部連結等方式，以增加網站在搜索引擎中的可見性和流量。SEO技術包括關鍵詞研究、優化內容、建立外部連結、改善網站速度和提升用戶體驗等。有效的SEO可以增加網站的有機流量，提升品牌曝光度，並增加潛在客戶的轉換率。`,
    pic:"SEO.jpg"
  },
  {
    label: '打造 Line Bot 創新應用',
    description: `Line Bot是一種基於Line平台的智能對話機器人，能夠透過文字、圖片、視頻等方式與使用者進行互動。Line Bot能夠執行各種任務，如提供服務、回答問題、執行訂單等。開發者可以使用Line Bot API來建立和定制自己的機器人，並整合各種功能，如自然語言處理、人工智慧等技術，以提供更智能、更有趣的互動體驗。Line Bot的應用範圍廣泛，可應用於客戶服務、資訊查詢、訂單管理等各個領域，為企業和用戶帶來更便捷、更豐富的通訊體驗。`,
    pic:"linebot.jpg"
  },
];

const flows = [
  {id:"01",title:"需求評估",detail:"規格了解與評估"},
  {id:"02",title:"簽訂合約",detail:"保障雙方權益"},
  {id:"03",title:"細節規格",detail:"確認各項規格"},
  {id:"04",title:"正式開發",detail:"開發與環境佈署"},
  {id:"05",title:"測試驗收",detail:"檢驗以符合標準"},
  {id:"06",title:"交付成品",detail:"結案並持續維護"},
]

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows:false,
  centerMode:true,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 850,
      settings: {
        vertical:true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};



function App() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [show,setShow] = useState("none");
  const handleMenu = ()=>{
    setAnchorEl(!anchorEl);
  }
  const handleClose = ()=>{
    setAnchorEl(false);
  }

  const resize = ()=>{
    if (window.innerWidth>=900){
      setAnchorEl(false);
    }
  }

  const handleShow = ()=>{
    if (window.scrollY>=300){
      setShow("flex");
    }else{
      setShow("none");
    }
  }

  const handleHyperLink = (target) =>{
    const div = document.querySelector(target);
    window.scrollTo({top:div.offsetTop-60,behavior:'smooth'});
  }

  useEffect(()=> window.addEventListener('resize',resize), []);
  useEffect(()=> window.addEventListener('scroll',handleShow), []);


  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  let sliderRef = useRef(null);
  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    sliderRef.slickNext();
  };
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    sliderRef.slickPrev();
  };




  function LineIcon() {
    return (
      <SvgIcon>
         <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm1-12.508c0-3.58-3.589-6.492-8-6.492s-8 2.912-8 6.492c0 3.209 2.846 5.897 6.691 6.405.26.056.615.172.704.395.081.202.053.519.026.723l-.114.685c-.035.202-.161.791.693.431.854-.36 4.607-2.713 6.286-4.645h-.001c1.16-1.271 1.715-2.561 1.715-3.994zm-10.978 2.067h-2.242l-.108-.043v-.001l-.001-.001-.003-.003-.044-.107v-3.486c0-.086.07-.156.156-.156h.562c.085 0 .155.07.155.156v2.768h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156zm8.314 0h-2.242l-.108-.043-.004-.005-.044-.107v-3.486l.044-.107.004-.005.108-.044h2.242c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156h-1.525v.589h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156h-1.525v.589h1.525c.086 0 .155.07.155.156v.561c0 .086-.069.156-.155.156zm-6.961 0h-.562c-.086 0-.156-.069-.156-.155v-3.486c0-.086.07-.156.156-.156h.562c.086 0 .155.07.155.156v3.486c0 .085-.069.155-.155.155zm1.418 0h-.561c-.086 0-.156-.069-.156-.155v-3.486c0-.086.07-.156.156-.156h.561l.013.001.015.002.009.002.015.004.005.002.014.006.013.008.008.005.012.01.003.003.01.011.013.015 1.597 2.158v-2.071c0-.086.07-.156.156-.156h.561c.086 0 .156.07.156.156v3.486c0 .085-.07.155-.156.155h-.561l-.04-.005-.002-.001-.016-.005-.007-.003-.012-.006-.01-.006-.001-.001-.04-.039-1.599-2.16v2.071c0 .085-.07.155-.156.155z"/></svg>
      </SvgIcon>

    );
  }

  const handleIconClick = (name) => {
    if (name=="line"){
      window.open("https://lin.ee/VcMOMSt","_blank");
    }else{
      window.open("https://www.instagram.com/jyh_works?igsh=Zm5iczR0anlvZmV3&utm_source=qr","_blank");
    }
  }

  const actions = [
    { icon: <InstagramIcon onClick={()=>handleIconClick("instagram")} />, name: 'instagram' },
    { icon: <LineIcon onClick={()=>handleIconClick("line")} />, name: 'line' },
  ];
  return (
    <div className="App" id="about">

      {/* Back to top */}
      <Stack direction="column" spacing={2} sx={{position:'fixed', bottom: 10, right:10,zIndex:1}}>
        <SpeedDial
          ariaLabel="social media"
          icon={<PhoneIcon />}
          FabProps={{
            sx:{bgcolor:"rgb(248, 202, 65)","&:hover":{bgcolor:"rgb(248, 202, 65)"}}
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              
            />
          ))}
        </SpeedDial>
        <a onClick={()=>handleHyperLink("#about")} className='backtop'>
          <Stack sx={{display:show,width:"55px",height:"55px",borderRadius:"100%",bgcolor:"rgb(248, 202, 65)",boxShadow:7,alignItems:"center",justifyContent:"center"}}>
            <KeyboardArrowUpIcon sx={{color:"white",fontSize:"30px"}} />
          </Stack>
        </a>
      </Stack>
 
  
       {/* 選單列 */}
      <Box component="nav" sx={{ display: 'flex',height:"90px"}}>
        <AppBar sx={{bgcolor:"white",color:'black'}}>
          <Toolbar sx={{display:'flex',height:"90px",mx:"auto"}}>
            <div><img src="img/logo.png" width="95px"/></div>
            {/* <Typography variant="h6" component="div" sx={{flexGrow:1,fontWeight:800}}>
              JYH WORKS
            </Typography> */}
            <Box sx={{display:{xs:'none',md:'block'},textAlign:"left"}}>
                {menuItem.map((item)=>(
                    <Button sx={{fontWeight:800}} onClick={()=>handleHyperLink(item.id)} className='menuItem' component="button" key={item.title} color="inherit">{item.title}</Button>
                ))}
            </Box>
            <IconButton onClick={handleMenu} sx={{display:{md:'none'}}} color="inherit"> 
              <MenuIcon  />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>

      {/* 選單欄 */}
      <Drawer open={anchorEl} anchor='right' onClick={handleClose} sx={{display:{md:'none'}}}>
        <Box sx={{width:"300px"}} >
          <Typography variant='h5' sx={{textAlign:"center",p:2,bgcolor:"rgb(248, 202, 65)",fontWeight:800}} gutterBottom>JYH</Typography>
        
          <List>
            {menuItem.map((item)=>(
                <ListItem key={item.title} disablePadding>
                  <ListItemButton className='drawerbtn' sx={{textAlign:"center"}} onClick={()=>handleHyperLink(item.id)}><ListItemText><Typography sx={{fontWeight:600}}>{item.title}</Typography></ListItemText></ListItemButton>
                </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
     
     
      <Stack spacing={2}>
        {/* 區塊一: 關於我們 */}
        <Box component="section"  className='triangle'>
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={5} xl={6} sx={{display:"flex",alignItems:"center"}}>
                  <About />
              </Grid>
              <Grid item xs={12} md={8} lg={7} xl={6}>
                  <Service />
              </Grid>
            </Grid>
          </Box>
        </Box>


        {/* 區塊二: why? */}
        <Box component="section">
          <Box sx={{py:"6rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
            <Typography variant='h3' sx={{fontWeight:800,mb:3}} gutterBottom>為什麼選擇JYH團隊？</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TerminalIcon sx={{fontSize:"130px"}}/>
                <Typography variant='h4' sx={{fontWeight:800}}>精湛技術</Typography>
                <Typography variant='h6' sx={{color:"text.secondary"}}>高品質程式技術，打造優質編碼</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <ChatIcon sx={{fontSize:"130px"}}/>
                <Typography variant='h4' sx={{fontWeight:800}}>即刻回應</Typography>
                <Typography variant='h6' sx={{color:"text.secondary"}}>簽約付款後不怠慢，快速回覆客戶任何問題</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TerminalIcon sx={{fontSize:"130px"}}/>
                <Typography variant='h4' sx={{fontWeight:800}}>智慧開發</Typography>
                <Typography variant='h6' sx={{color:"text.secondary"}}>打造性價比最高的產品，省下您的荷包</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

      


        {/* 區塊三: 專案流程 */}
        <Box component="section" sx={{bgcolor:"rgb(248, 202, 65)",color:"#333"}} >
          <Box sx={{py:"2rem",px:{xs:"1rem",md:"4rem",lg:"6rem",xl:"2rem"},mx:"auto"}}>
            <Grid container spacing={2} sx={{mx:"auto",display:"flex",minHeightheight:"500px"}}>
              {flows.map((flow)=>(
                <Grid item xs={6} md={6} lg={4} xl={2} sx={{display:"flex",alignItems:"center",alignContent:"center",mx:"auto"}}>
                  <Grid item>
                    <Typography variant='h1' sx={{fontWeight:900,mr:2}}>{flow.id}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h5' sx={{fontWeight:700,textAlign:"left"}}>{flow.title}</Typography>
                    <Typography component="div" variant='body1' sx={{fontWeight:700,color:"white",textAlign:"left"}}>{flow.detail}</Typography>
                  </Grid>
                </Grid>
              ))} 
            </Grid>
          </Box>
        </Box>


         {/* 區塊四: 方案介紹 */}
        <Box component="section" id="plan">
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
              <Cases />
          </Box>
        </Box>

      {/* 區塊五: 網頁小知識 */}

      <Box component="section" id="kw" sx={{bgcolor:"rgb(248, 202, 65,0.8)",position:"relative"}}>
          <Box sx={{py:"2rem",px:{xs:"1rem",md:"2rem",lg:"4rem",xl:"8rem"}}}>
          <Slider {...settings} 
              ref={slider => {sliderRef = slider;}}
          >
            {steps.map((step)=>(
                <div>
                <Card sx={{ width:345,boxShadow:3,borderRadius:8,height:500,mx:"auto"}}>
                    <CardMedia
                      sx={{ height: 200 }}
                      image={"img/" + step.pic}
                      title={step.pic}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {step.label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{textAlign:"left"}}>
                        {step.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ))
            }
          </Slider>
          <Grid container  justifyContent="space-between" sx={{position:"absolute",top:"50%",left:0,width:"100%"}}>
            <Button sx={{color:"#000",bgcolor:"rgb(237,176,55,0.5)",'&:hover':{bgcolor:{xs:"rgb(237,176,55,0.5)",lg:"rgb(237,176,55)"}},borderRadius:"100%"}} onClick={handleBack}>
              <KeyboardArrowLeft sx={{width:"50px",height:"50px"}} />
            </Button>
            <Button sx={{color:"#000",bgcolor:"rgb(237,176,55,0.5)",'&:hover':{bgcolor:{xs:"rgb(237,176,55,0.5)",lg:"rgb(237,176,55)"}},borderRadius:"100%"}} onClick={handleNext}>
                <KeyboardArrowRight sx={{width:"50px",height:"50px"}} />
            </Button>
          </Grid>

             
          </Box>

        </Box>

      {/* <Box component="section" id="kw" sx={{bgcolor:"rgb(248, 202, 65,0.8)",position:"relative"}}>
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
              <Knowledge activeStep={activeStep} maxSteps={maxSteps} steps={steps} />
          </Box>
          <Grid container  justifyContent="space-between" sx={{position:"absolute",top:"50%",left:0,width:"100%"}}>
            <Button sx={{color:"#000",bgcolor:"rgb(237,176,55,0.5)",'&:hover':{bgcolor:{xs:"rgb(237,176,55,0.5)",lg:"rgb(237,176,55)"}},borderRadius:"100%"}} onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft sx={{width:"50px",height:"50px"}} />
            </Button>
            <Button sx={{color:"#000",bgcolor:"rgb(237,176,55,0.5)",'&:hover':{bgcolor:{xs:"rgb(237,176,55,0.5)",lg:"rgb(237,176,55)"}},borderRadius:"100%"}} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                <KeyboardArrowRight sx={{width:"50px",height:"50px"}} />
            </Button>
          </Grid>
        </Box>
        */}
  
        {/* 區塊六: 團隊成員 */}
        <Box component="section" id="member">
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
            <Typography variant='h3' sx={{fontWeight:800}} gutterBottom>專業團隊</Typography>
            <Member />
          </Box>
        </Box>
        
        {/* 區塊七: 成功案例
        <Box component="section" id="case">
          
        </Box> */}


        {/* 區塊八: 聯絡我們 */}
        <Box component="section" id="contact" className='triangle2'>
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
            <Contact />
          </Box>
        </Box>
      </Stack>

      {/* footer */}
      <Box component="footer" id="footer" sx={{bgcolor:"#fcfcfc"}}>
          <Box sx={{py:"2rem",px:{xs:"2rem",md:"4rem",lg:"8rem",xl:"16rem"}}}>
            <Grid container spacing={2} columnSpacing={9}>

                <Grid item xs={12} lg={3} >
                  <Studiofooter/>
                </Grid>

                <Grid item xs={12} lg={6} >
                  <Servicefooter/>
                </Grid>

                <Grid item xs={12} lg={3} >
                  <Contactfooter/>
                </Grid>

            </Grid>

            <Divider sx={{my:2}} />
            <Typography variant='subtitle1' color="text.secondary" >版權 © 2024 - JYH WORKS 開發</Typography>
          </Box>
      </Box>
    </div>
  );
}

export default App;
